export const WEBSITE_NAME = 'Omni';

export const NONE = 'javascript:void(0)';

export const OMNI_WIKI_PAGE = 'https://w.amazon.com/bin/view/AWSCP/PRET/Services/DataPlatform/Omni/';
export const DGS_TEAM_WIKI = 'https://w.amazon.com/bin/view/AWSDataPlatform/AWSDataLake';
export const FEATURE_REQUEST_URL = 'https://sim.amazon.com/issues/create?template=eca3d756-6bd2-43ae-8950-020629c0a94b';

export const DATA_LAKE_CTI_URL =
  'https://t.corp.amazon.com/create/options?ticket_type=Regular+Ticket&severity=FIVE&category=AWS&type=DataLake&item=Catalog+Issues&group=AWS+Datalake&assignee=aws-datalake-primary';

export const GLOBAL_TEMPLATE_MODIFICATION_REQUEST_TICKET_TEMPLATE_LINK =
  'https://t.corp.amazon.com/create/templates/ffd442b2-53b3-4022-a896-40f83e8bb6bc';

export const OMNI_TERMS_AND_CONDITIONS_URL = 'https://w.amazon.com/bin/view/AWSDataPlatform/Omni/TermsAndConditions';

export const AWS_DATA_HANDLING_POLICY_URL = 'https://policy.a2z.com/docs/646/publication';

export const INVALID_PARAMETER_EXCEPTION_NAME = 'InvalidParameterException';

export const VALIDATE_IS_ISENGARD_ACCOUNT = 'ValidateIsIsengardAccount';
export const VALIDATE_IS_PRODUCTION_ACCOUNT = 'ValidateIsProductionAccount';
export const VALIDATE_IS_ISENGARD_ACCOUNT_CUSTOM_PATTERN = 'ValidateIsIsengardAccount_';
export const VALIDATE_IS_PRODUCTION_ACCOUNT_CUSTOM_PATTERN = 'ValidateIsProductionAccount_';
export const GLOBAL_TEMPLATE_RULE_NAME_TO_USER_FRIENDLY_RULE_NAME = {
  ValidateIsIsengardAccount: 'Account must be managed by Isengard',
  ValidateIsProductionAccount: 'Account must be production',
};

export const OMNI_ROLES_WIKI_URL =
  'https://w.amazon.com/bin/view/AWSDataPlatform/Omni/Workspaces/#HHowtoOrganizeOmniGroupsandWorkspaces';

export const NON_PRODUCTION_ACCESS_WIKI_URL =
  'https://w.amazon.com/bin/view/AWSCP/PRET/Services/DataPlatform/Omni/NonProductionAccountAccess';

export const NON_ISENGARD_ACCESS_WIKI_URL =
  'https://w.amazon.com/bin/view/AWSCP/PRET/Services/DataPlatform/Omni/NonIsengardAccountAccess';

export const NON_PRODUCTION_ACCESS_GLADSTONE_ERROR_PREFIX = 'Accounts are not production accounts: ';

export const NON_ISENGARD_ACCESS_GLADSTONE_ERROR_PREFIX = 'Accounts are not managed by Isengard: ';

export const PHONE_TOOL_URL_USER_FORMAT = 'https://phonetool.amazon.com/users/';

export const ADMIN_GROUPS = [
  'AWSDL_Services',
  'AWSDataWarehouse',
  'AWS-DW-PRIMARY',
  'AWS-DW-PRIMARY-GLOBAL',
  'AWS-DW-PRIMARY-IAD',
];

// possible values for DataClassification
export const dataClassificationOptions = [
  {
    label: 'Public',
    id: '1',
  },
  {
    label: 'Private',
    id: '2',
  },
  {
    label: 'Restricted',
    id: '3',
  },
];
// possible values for RefreshCadence
export const refreshCadenceOptions = [
  {
    label: 'Hourly',
    id: '1',
  },
  {
    label: 'Daily',
    id: '2',
  },
  {
    label: 'Weekly',
    id: '3',
  },
  {
    label: 'Monthly',
    id: '4',
  },
  {
    label: 'Never',
    id: '5',
  },
];

export const TABLE_STATUS_DEPRECATED = 'Deprecated';

// possible values for Table Status
export const tableStatusOptions = [
  {
    label: 'Pending Deprecation',
    id: '1',
  },
  {
    label: 'Deprecated',
    id: '2',
  },
  {
    label: 'Active',
    id: '3',
  },
];

export const LAKEFORMATION_RESOURCE = 'LAKEFORMATION';
export const REDSHIFT_RESOURCE = 'REDSHIFT';
export const HC_METADATA_FORM_RESOURCE = 'METADATA_FORM';

export const DATACONSUMER = 'DATACONSUMER';

export const DATAPROVIDER = 'DATAPROVIDER';

export const DATAPROVIDER_AND_CONSUMER = 'DATAPROVIDER_AND_DATACONSUMER';

export const WORKSPACEUSER = 'WORKSPACEUSER';

// tag type
export const LAKEFORMATION_TAG_TYPE = 'LF';
export const REDSHIFT_TAG_TYPE = 'REDSHIFT';

// fga policy type
export const LAKEFORMATION_FGA_POLICY_TYPE = 'FineGrainedAccess-LF';
export const REDSHIFT_FGA_POLICY_TYPE = 'FineGrainedAccess-Redshift';
export const FGA_POLICY_TYPE_PREFIX = 'FineGrainedAccess';
export const STATUS_ACTIVE = 'Active';
export const STATUS_PENDING = 'Pending';
export const STATUS_INACTIVE = 'InActive';
export const INCLUDE_COLUMNS_FILTER = 'Include_columns';
export const EXCLUDE_COLUMNS_FILTER = 'Exclude_columns';
export const NO_COLUMN_FILTER = 'Access_to_all_columns';

// possible values for FGA policy Types
export const fgaPolicyTypeSelection = [
  {
    label: 'LF',
    value: LAKEFORMATION_FGA_POLICY_TYPE,
  },
  {
    label: 'Redshift',
    value: REDSHIFT_FGA_POLICY_TYPE,
  },
];

export const fgaPolicyVisibilitySelection = [
  {
    label: 'Public',
    value: 'Public',
  },
  {
    label: 'Private',
    value: 'Private',
  },
  {
    label: 'Restricted',
    value: 'Restricted',
  },
];

export const fgaPolicyStatusSelection = [
  {
    label: 'Active',
    value: 'Active',
  },
  {
    label: 'InActive',
    value: 'InActive',
  },
];

//permission type
export const REDSHIFT_PERMISSION_TYPE = 'REDSHIFT';

//data permissions type
export const DATA_PERMISSION_CONSUMER_OPTION = 'Consumer';
export const DATA_PERMISSION_PUBLISHER_OPTION = 'Publisher';
export const DATA_PERMISSION_LAKE_FORMATION_TYPE = 'LF';
export const DATA_PERMISSION_REDSHIFT_TYPE = 'REDSHIFT';
export const DATA_PERMISSION_IAM_TYPE = 'IAM';
export const DATA_PERMISSION_STATUS_ACTIVE = 'Active';
export const DATA_PERMISSION_STATUS_INACTIVE = 'InActive';

//cluster state
export const CLUSTER_RUNNING_STATE = 'RUNNING';

//datasource type
export const REDSHIFT_DATASOURCE_ID = 'redshift';
export const LAKE_FORMATION_DATASOURCE_ID = 'glueLF';
export const IAM_DATASOURCE_ID = 'glue';

// datasetId split details
export const ACCOUNT_ID_PREFIX = 'A-';
export const DATABASE_NAME_PREFIX = 'DN-';
export const TABLE_NAME_PREFIX = 'TN-';
export const REGION_NAME_PREFIX = 'R-';

// Group Wks dropdown id delimiter
export const GROUP_WKS_ID_DELIMITER = '##';

// resource type
export const SCHEMA_RESOURCE_TYPE = 'Schema';
export const DATASET_RESOURCE_TYPE = 'Dataset';
export const DATABASE_RESOURCE_TYPE = 'Database';
export const TABLE_RESOURCE_TYPE = 'TABLE';
export const RESOURCE_LINK_PREFIX = 'glueRL';
export const RESOURCE_LINK_RES_TYPE = 'RESOURCE_LINK';

// content type
export const TABLE_CONTENT_TYPE = 'table';
export const FORM_CONTENT_TYPE = 'form';
export const WIZARD_CONTENT_TYPE = 'wizard';
export const DEFAULT_CONTENT_TYPE = 'default';
export const CARDS_CONTENT_TYPE = 'cards';

//checkout item
export const REDSHIFT_TAG_ITEM = 'redshiftTag';
export const LAKE_FORMATION_TAG_ITEM = 'lakeFormationTag';
export const RESOURCE_ITEM = 'resource';
export const TAG_ITEM = 'tag';
export const addToCartAccessTypeSelection = [
  {
    label: 'Tag access',
    value: TAG_ITEM,
  },
  {
    label: 'Resource access',
    value: RESOURCE_ITEM,
  },
  {
    label: 'Fine grain access',
    value: FGA_POLICY_TYPE_PREFIX,
  },
];

// brass actor type
export const BRASS_ACTOR_TYPE_PRINCIPAL = 'principal';

// djs job url
export const DJS_JOB_URL_PREFIX = 'https://djs.amazon.com/jobs/';
// Exception Name
export const NOT_FOUND_EXCEPTION = 'NotFoundException';
// oncall url
export const DGS_ONCALL_URL = 'https://oncall.corp.amazon.com/#/view/aws-datalake-primary/schedule';

export const NUM_TAG_VALUES = 15;

export const DATA_LOAD_LIMIT = 100;

export const DATABASE_LOAD_LIMIT = 20;

export const FOUNDRY_TRUSTED_ROLES = [
  'AWSDGSFoundryCatalogDiscovery-TrustedRole-DO-NOT-DELETE',
  'AWSDGSFoundryCatalogDiscovery-TrustedRole-DO-NOT-DELETE-beta',
  'AWSDGSFoundryCatalogDiscovery-TrustedRole-DO-NOT-DELETE-gamma',
];

export const FOUNDRY_APPLICATION_ROLE = 'AWSDGSFoundry-ManagementRole-DO-NOT-DELETE';

// AWSDGSFoundryCatalog group. Default group for DGS services and catalog discovery for internal employees.
export const AWSDGSFOUNDRYCATALOG_GROUP_ID = 'AWSDGSFoundryCatalog';

export const DATABASE_KEY_LIST_LIMIT = 20;

export const ANALYTICS_FEDERATION_TIME_OUT = 60; // in minutes

// AWS DGS Service Name
export const DGS_SERVICE_NAME_HYBRID_CATALOG = 'dgs-hc';
export const DGS_SERVICE_NAME_GLADSTONE = 'dgs-gladstone';

// templates modal resource Types
export const TEMPLATE_TAG_RESOURCE_TYPE = 'Tag';
export const TEMPLATE_GROUP_RESOURCE_TYPE = 'Group';
export const TEMPLATE_WORKSPACE_RESOURCE_TYPE = 'Workspace';
export const TEMPLATE_CATALOG_RESOURCE_TYPE = 'Catalog';
export const TEMPLATE_SCHEMA_RESOURCE_TYPE = 'Schema';
export const TEMPLATE_DATASET_RESOURCE_TYPE = 'Dataset';
export const TEMPLATE_DATABASE_RESOURCE_TYPE = 'Database';
export const TEMPLATE_TABLE_RESOURCE_TYPE = 'Table';
export const TEMPLATE_FGAP_RESOURCE_TYPE = 'FineGrainedAccessPolicy';
export const TEMPLATE_LAKE_FORMATION = 'Lake Formation';
export const TEMPLATE_REDSHIFT = 'Redshift';
export const TEMPLATE_ACTIVE_STATUS = 'Active';
export const TEMPLATE_NONE = 'NONE';
export const TEMPLATE_GLOBAL = 'Global';
export const TEMPLATE_TYPE_ACCESS_MANAGEMENT = 'AccessManagement';
export const TEMPLATE_TYPE_GLOBAL_ACCESS_MANAGEMENT = 'GlobalAccessManagement';
export const APPROVALS_STATUS_REJECTED = 'REJECTED';
export const APPROVALS_STATUS_CANCELLED = 'CANCELLED';
export const APPROVALS_PRIORITY = 'LOW';
export const APPROVALS_STATUS_APPROVED = 'APPROVED';
export const APPROVALS_STATUS_APPROVED_WITH_CONDITION = 'APPROVED_WITH_CONDITION';

export const omniResourceTypes = [
  {
    label: 'LF',
    value: LAKEFORMATION_RESOURCE,
  },
  {
    label: 'Redshift',
    value: REDSHIFT_RESOURCE,
  },
];

export const hcGlossaryResourceTypes = [
  {
    label: 'LF',
    value: LAKEFORMATION_RESOURCE,
  },
  {
    label: 'Redshift',
    value: REDSHIFT_RESOURCE,
  },
  {
    label: 'Metadata Form',
    value: HC_METADATA_FORM_RESOURCE,
  },
];

export const resourceTypesForMetadataForm = [
  {
    value: 'lfCatalog',
    label: 'LakeFormation Catalog',
  },
  {
    value: 'lfDatabase',
    label: 'LakeFormation Database',
  },
  {
    value: 'lfTable',
    label: 'LakeFormation Table',
  },
  {
    value: 'lfColumn',
    label: 'LakeFormation Column',
  },
  {
    value: 'redshiftCluster',
    label: 'Redshift Cluster',
  },
  {
    value: 'redshiftDatabase',
    label: 'Redshift Database',
  },
  {
    value: 'redshiftSchema',
    label: 'Redshift Schema',
  },
  {
    value: 'redshiftTable',
    label: 'Redshift Table',
  },
  {
    value: 'redshiftColumn',
    label: 'Redshift Column',
  },
];

export const hcMetadataStatus = [
  {
    label: 'Enabled',
    value: 'Enabled',
  },
  {
    label: 'Disabled',
    value: 'Disabled',
  },
];

export const hcMetadataFormType = [
  {
    label: 'Global',
    value: 'Global',
  },
  {
    label: 'User',
    value: 'User',
  },
];

export const hcMetadataStringFormatType = [
  {
    label: 'Markdown',
    value: 'Markdown',
  },
  {
    label: 'Plaintext',
    value: 'Plaintext',
  },
];

export const hcMetadataFieldType = [
  {
    label: 'String',
    value: 'String',
  },
  {
    label: 'Number',
    value: 'Number',
  },
  {
    label: 'Boolean',
    value: 'Boolean',
  },
  {
    label: 'Business Glossary',
    value: 'BusinessGlossary',
  },
];

export const METADATA_BUSINESS_GLOSSARY = 'Glossary';

export const METADATA_FORM = 'Form';

export const METADATA_ENABLED = 'Enabled';

export const METADATA_FORM_PREFIX = 'mfm-';

export const HC_RESOURCE_ARN_PREFIX = 'arn:';

export const EXP_10_MINS = 10;
export const EXP_6_HOURS_MINS = 360;
export const EXP_12_HOURS_MINS = 720;
export const EXP_24_HOURS_MINS = 1440;
export const EXP_1_WEEK_MINS = 10080;
// Max workspaceIds that can be passed to ListWorkspaces API
export const LIST_WKS_MAX_WORKSPACE_IDS = 50;
export const OMNI_ONCALL_URL = 'https://oncall.corp.amazon.com/#/view/aws-datalake-primary/schedule';

export const GLADSTONE_STRING_LIST_MAX_LENGTH = 100;

export const CONTACT_CATEGORY = 'Category';

export const CONTACT_TYPE = 'Type';

export const CONTACT_ITEM = 'Item';

export const CONTACT_WIKI = 'Wiki';

export const CONTACT_OWNER = 'Contact Owner';

export const CONTACT_ONCALL_ROTATION = 'Contact Oncall Rotation';

export const CONTACT_CTI = 'CTI';

export const CONTACT_PRIMARY = 'Primary';

export const CONTACT_SECONDARY = 'Secondary';

export const CONTACT_NOTES = 'Contact Notes';

export const CONTACT_PRIMARY_OWNER_FORM_NAME = 'Primary Contact Owner';

export const CONTACT_SECONDARY_OWNER_FORM_NAME = 'Secondary Contact Owner';
// export const CONTACT_DISPLAY_FORM = 'Display Form';

export const DISPLAYFORMONPAGE_DETAIL = 'Detail';

export const DW_TABLE_NAMES_WITH_MP_DATA = new Set<String>([
  'All Tables',
  'atars_bills',
  'atars_lineitems',
  'aws_revenue_all_details',
  'aws_savings_plan_revenue_details',
  'dim_aws_offering_pricing_plans',
  'dim_aws_products',
  'd_daily_metering_sum',
  'd_monthly_metering_sum',
  'd_weekly_metering_sum',
  'fact_aws_anniversary_revenue',
  'fact_aws_anniversary_revenue_star',
  'fact_aws_daily_ocb_rev',
  'fact_aws_daily_ocb_rev_star',
  'fact_aws_ocb_anniversary_revenue',
  'fact_aws_ocb_anniversary_revenue_star',
  'gt_o_aws_offerings',
  'o_aws_allocated_bills',
  'o_aws_allocated_bill_line_items',
  'o_aws_bills',
  'o_aws_bill_currency_amounts',
  'o_aws_bill_family',
  'o_aws_bill_family_children',
  'o_aws_bill_ver',
  'o_aws_credit_bill_line_items',
  'o_aws_line_items',
  'o_aws_line_item_asns',
  'o_aws_offerings',
  'o_aws_packages',
  'o_aws_payer_bills',
  'o_aws_payer_bill_line_items',
  'o_aws_pricing_plans',
  'o_aws_products',
  'o_aws_rates',
  'o_hourly_aws_usage_history',
  'tims_vat_invoice_line_item',
]);

export const DW_IAD_LF_ACCOUNT = '696396209923';

export const DW_GDP_LF_ACCOUNT = '918812439948';

export const DW_IAD_LF_DB_NAME = 'aws_datalake_processed';

export const DW_GDP_LF_DB_NAME = 'awsdl_processed';

export const WORKSPACE_PREFIX = 'wks-';

// CSV download constants
export const ALL_TABLES = 'ALL_TABLES';
export const PERM_TYPE_LF = 'LF';
export const PERM_TYPE_LF_FGAC = 'LF-FGAC';
export const PERM_TYPE_LF_TAG = 'LF-Tag';
export const PERM_TYPE_REDSHIFT = 'REDSHIFT';
